import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const ContactContainer = styled.div`
  margin: auto;
  max-width: 1200px;
  padding: 50px;

  .header {
    text-align: center;
  }

  input,
  button,
  textarea {
    display: block;
    margin: 0 0 15px 0;
    padding: 10px 15px;
    width: 100%;

    border: none;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
  }

  textarea {
    height: 150px;
  }

  button {
    background: #3e9ed6;
    color: #ffffff;

    &:active {
      background: #388fc2;
    }
  }
`

class Contact extends PureComponent {
  render() {
    return (
      <Layout>
        <SEO title="Contact Us" keywords={['contact']} />
        <ContactContainer>
          <Grid container>
            <Grid item xs={12} className="header">
              <h1>Get In Touch</h1>
            </Grid>
            <Grid item sm={3} />
            <Grid item xs={12} sm={6}>
              <p>
                Shoot us a message and we'll get back to you as soon as we can.
              </p>
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                action="/success"
              >
                <input name="Email" type="text" placeholder="Email" />
                <textarea name="Message" placeholder="Message" />
                <button type="submit">Send</button>
              </form>
            </Grid>
          </Grid>
        </ContactContainer>
      </Layout>
    )
  }
}

export default Contact
