import React, { PureComponent } from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'

import Header from './Header'

const LayoutContainer = styled.div`
  padding: 35px 0;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`

const Footer = styled.footer`
  margin: 25px 0;
  text-align: center;
`

class Layout extends PureComponent {
  render() {
    const { children } = this.props

    return (
      <LayoutContainer>
        <Grid container>
          <Grid item xs={12}>
            <Header />
            {children}
            <Footer>© {new Date().getFullYear()}, Let's Go Paintball</Footer>
          </Grid>
        </Grid>
      </LayoutContainer>
    )
  }
}

export default Layout
