import React, { PureComponent } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import Logo from '../../content/assets/full_logo.svg'

const HeaderContainer = styled.div`
  margin: auto;
  max-width: 1200px;
  padding: 0 50px;

  .navigation {
    text-align: right;

    ul {
      li {
        display: inline;
        padding: 0 0 0 25px;

        font-size: 1.25rem;
      }
    }

    a {
      padding: 10px 10px 5px 10px;

      color: #ffffff;

      &.selected {
        border-bottom: 3px solid #3e9ed6;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .logo {
      padding: 20px;
    }

    .logo,
    .navigation {
      text-align: center;

      ul li {
        padding: 0;
      }
    }
  }
`

class Header extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={headerQuery}
        render={data => (
          <HeaderContainer>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={3} className="logo">
                <Link to="/">
                  <Logo />
                </Link>
              </Grid>
              <Grid item xs={12} sm={9} className="navigation">
                <ul>
                  {/* <li>
                    <Link activeClassName="selected" to="/blog">
                      Blog
                    </Link>
                  </li> */}
                  <li>
                    <Link activeClassName="selected" to="/contact">
                      Contact
                    </Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </HeaderContainer>
        )}
      />
    )
  }
}

const headerQuery = graphql`
  query {
    logo: file(absolutePath: { regex: "/full_logo.svg/" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Header
